import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import "./EditSales.scss"
import Card from "../../components/card/Card";
import ProductForm from "../../components/sales/salesForm/SalesForm";
import {
    getSale,
    getSales,
    selectIsLoading,
    selectProduct,
    updateSale,
} from "../../redux/features/sales/salesSlice";

const EditSales = () => {
    useRedirectLoggedOutUser("/login");
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector(selectIsLoading);

    const productEdit = useSelector(selectProduct);

    const [product, setProduct] = useState(productEdit);
    const [productImage, setProductImage] = useState("");
    const [imagePreview, setImagePreview] = useState(null);
    const [description, setDescription] = useState("");

    useEffect(() => {
        dispatch(getSale(id));
    }, [dispatch, id]);

    useEffect(() => {
        setProduct(productEdit);

        setImagePreview(
            productEdit && productEdit.image ? `${productEdit.image.filePath}` : null
        );

        setDescription(
            productEdit && productEdit.description ? productEdit.description : ""
        );
    }, [productEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProduct({ ...product, [name]: value });
    };

    const handleImageChange = (e) => {
        setProductImage(e.target.files[0]);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
    };

    const saveProduct = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", product?.name);

        formData.append("category", product?.category);
        formData.append("quantity", product?.quantity);
        formData.append("price", product?.price);
        formData.append("description", description);
        if (productImage) {
            formData.append("image", productImage);
        }

        console.log(...formData);

        await dispatch(updateSale({ id, formData }));
        await dispatch(getSales());
        navigate("/inventory");
    };

    return (
        <div>
            {isLoading && <Loader />}
            <h3 className="--mt"></h3>
            <div className="edit-sales">
            <ProductForm className="meuovo"
                product={product}
                productImage={productImage}
                imagePreview={imagePreview}
                description={description}
                setDescription={setDescription}
                handleInputChange={handleInputChange}
                handleImageChange={handleImageChange}
                saveProduct={saveProduct}
            />
            </div>


        </div>
    );
};

export default EditSales;
